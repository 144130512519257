@import 'reset';
@import 'fonts';
@import 'vars';
@import 'mixins';




html {
    @media all and (min-width: 940px) {
        zoom: 70%
    }
    @media all and (min-width: 1080px) {
        zoom: 80%
    }
    @media all and (min-width: 1280px) {
        zoom: 90%
    }
    @media all and (min-width: 1400px) {
        zoom: 100%
    }
}
