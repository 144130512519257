@import (reference) '../../../assets//styles/styles.less';

.layout {
    width: 1400px;
    margin: auto;
}

@media all and (max-width: @mobileWidth) {
    .layout {
        width: 100%;
    }
}
