@import (reference) '../../../assets/styles/styles.less';

.container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/blurrySmalBcgFrame.png');
    height: 379px;
    width: 299px;
    padding: 16px;
    align-items: center;

    .imgWrapper {
        width: 267px;
        height: 212px;
        border-image: linear-gradient(180deg, #75c062 0%, #2a6d1a 100%) 10;
        border-width: 1px;
        border-style: solid;

        .blurryCardImg {
            width: 100%;
            height: 100%;
            filter: saturate(0);
            transition: all 0.5s ease-in;

            &:hover {
                filter: none;
            }
        }
    }

    .blurryCardTitle {
        @furoreRegular();
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        white-space: pre-wrap;
        color: @white;
        padding: 20px 0;
        text-decoration: none;
        width: 92%;
    }

    .btnWrapper {
        margin-top: auto;
    }
}

.container:hover .blurryCardImg {
    filter: none;
}
