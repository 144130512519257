@import (reference) '../../../assets/styles/styles.less';

.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 630px;
    height: 319px;
    background: @grey3;
    padding: 20px;
    gap: 25px;

    &.big {
        width: 1280px;
        height: 319px;
    }

    &.small {
        height: 240px;
        width: 625px;

        .text {
            font-size: 16px;
            line-height: 19px;
            text-align: left;
        }

        .title {
            font-size: 20px;
            line-height: 23px;
        }
    }
}

.imgWrapper {
    flex: 0 1 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.textWrapper {
    flex: 0 1 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    @robotoRegular();
    font-size: 24px;
    line-height: 28px;
    color: @white;
    margin-bottom: 20px;
    white-space: pre-wrap;
}

.text {
    @robotoRegular();
    font-size: 20px;
    line-height: 24px;
    color: @white;
    white-space: pre-wrap;
}

.detailsBtn {
    @textButton();
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;

    &:hover {
        color: @green6;
    }
}

.arrowIcon {
    display: none;
}

@media all and (max-width: @mobileWidth) {
    .card {
        position: relative;
        height: 210px;
        width: 280px;
        display: block;

        &.big {
            height: 210px;
            width: 280px;
        }

        &.adaptive {
            min-height: 210px;
            height: max-content;
            padding: 10px;
        }
    }

    .arrowIcon {
        position: absolute;
        z-index: 499;
        display: block;
        right: 20px;
        bottom: 20px;
        width: 42px;
        height: 18px;
    }

    .arrowIconBack {
        position: absolute;
        z-index: 499;
        display: block;
        left: 10px;
        bottom: 20px;
        width: 42px;
        height: 18px;
        transform: rotate(180deg);
    }

    .text {
        display: none;
    }
    .title {
        display: none;
    }
    .detailsBtn {
        display: none;
    }

    .mobileTextBlock {
        width: 100%;
        padding-bottom: 40px;
    }

    .mobileTitle {
        @robotoRegular();
        font-size: 20px;
        line-height: 23px;
        color: #ffffff;
        margin-bottom: 5px;
    }

    .mobileText {
        @robotoRegular();
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
    }

    .mobileImg {
        display: none;
    }
}
