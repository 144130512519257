@import (reference) '../../../assets/styles/styles.less';

.blurryTopFrame {
    position: absolute;
    width: 100%;
    height: 134px;
    background: @black;
    filter: blur(125px);
}

.container {
    position: relative;
    width: 100%;
    height: 649px;
    background: @grey3;
    display: flex;
}

.blurredFrame {
    background-image: url('../../../assets/images/png/blurryContactsBcgFrame.png');
    width: 50%;
    height: 649px;
    padding: 171px 160px 98px 66px;

    .mainText {
        @furoreRegular();
        font-size: 40px;
        color: @grey5;
        letter-spacing: 0.135em;
        line-height: 46px;
        margin-bottom: 60px;
    }

    .secondaryText {
        @robotoLight();
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.135em;
        color: @white;
        margin-top: 20px;

        a {
            color: @white;
            text-decoration: none;
            @robotoLight();
        }
    }
}

.mapContainer {
    height: 447px;
    width: 634px;
    margin-top: 141px;
    position: relative;
    cursor: pointer;

    .map {
        width: 100%;
        height: 100%;
        background: @black;

        .mapImg {
            opacity: 0.5;
            width: 100%;
            height: 100%;
            filter: saturate(0%);
            transition: all 0.4s;

            &:hover {
                filter: none;
                opacity: 0.8;
            }
        }
    }

    .imgWrapper {
        position: absolute;
        top: 91px;
        left: 228px;
    }
}

@media all and (max-width: @mobileWidth) {
    .blurryTopFrame {
        display: none;
    }

    .textBlock {
        width: 280px;
    }

    .container {
        align-items: center;
        height: 491px;
        flex-direction: column;
    }

    .blurredFrame {
        height: 491px;
        background: none;
        padding: 0;
        width: 320px;
        padding: 87px 22px 26px 22px;

        .mainText {
            font-size: 20px;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0.24em;
            text-align: left;

            margin-bottom: 210px;
        }

        .secondaryText {
            position: relative;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.135em;
            color: #ffffff;
            margin-top: 8px;
        }
    }

    .mapContainer {
        height: 195px;
        width: 277px;
        position: absolute;
        top: -15px;

        .imgWrapper {
            top: 40px;
            left: 100px;
            img {
                height: 46px;
                width: 35px;
            }
        }
    }
}
