@import (reference) '../../../assets/styles/styles.less';

.roboto {
    font-family: 'Roboto', sans-serif;
}


.greenText {
    font-style: normal;
    background: linear-gradient(0deg, #75C062, #75C062), linear-gradient(76.82deg, #576265 11.6%, #9EA1A1 31.94%, #576265 38.59%, #848B8A 57.27%, #576265 70.14%, #757A7B 85.34%, #576265 91.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, normal;
}

.listItem {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 30px;
    position: relative;
    padding-left: 23px;

    &:before {
        content: '';
        background: url("../../../assets/images/svg/greenDot.svg") 0 0 no-repeat;
        width: 14px;
        height: 14px;
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
    }
}

.souzWrapper {

    .block1 {
        background: url("../../../assets/images/jpg/souz1.jpg") 0 0 no-repeat;
        height: 700px;
        box-sizing: border-box;
        padding: 187px 0 0 65px;

        h1 {
            @furoreRegular();
            font-size: 36px;
            line-height: 41px;
            letter-spacing: 0.135em;
            color: @white;
        }

        .greenText {
            @furoreRegular();
            font-size: 64px;
            line-height: 74px;
            letter-spacing: 0.135em;
            background: linear-gradient(0deg, #75C062, #75C062), linear-gradient(75.4deg, #576265 15.32%, #9EA1A1 37.62%, #576265 44.9%, #848B8A 65.38%, #576265 79.49%, #757A7B 96.16%, #576265 102.7%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            background-blend-mode: color, normal;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            margin-top: 45px;
        }

        .descWrapper {
            margin-top: 45px;

            .listItem {
                color: #E1E1E1;
            }
        }
    }

    .block2 {
        box-sizing: border-box;
        height: 549px;
        padding: 60px 0 0 65px;
        background: url("../../../assets/images/jpg/souz2.jpg") 0 0 no-repeat;
        display: flex;
        justify-content: space-between;

        .textBlock1, .textBlock2 {
            width: 45%;

            .blockHead, .blockHeadBlack {
                @furoreRegular();
                font-size: 24px;
                line-height: 28px;
                letter-spacing: 0.135em;
                color: #D9D9D9;
                margin-bottom: 30px;
            }

            .blockHeadBlack {
                color: #494949;
            }

            .listItem {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                letter-spacing: 0.08em;
            }

        }

        .textBlock1 {
            .listItem {
                color: @white;
            }
        }

        .textBlock2 {
            .listItem {
                color: #252525;
            }
        }

    }

    .block3 {
        height: 360px;
        background: #D9D9D9;
        display: flex;
        justify-content: center;
        gap: 180px;

        .columnItem {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .descr {
                @furoreRegular();
                font-size: 20px;
                line-height: 23px;
                text-align: center;
                letter-spacing: 0.135em;
                max-width: 298px;
                color: @black;
                margin-top: 27px;
            }
        }
    }

    .block4 {
        background: #414141;
        height: 510px;
        padding: 50px 65px 0 65px;

        h2 {
            @furoreRegular();
            font-size: 32px;
            line-height: 37px;
            letter-spacing: 0.135em;
            color: #D9D9D9;
            margin-bottom: 30px;
        }

        .blocksPlace {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .numberBlock {
                position: relative;
                max-width: 300px;

                .number {
                    @furoreRegular();
                    font-size: 85px;
                    line-height: 85px;
                    letter-spacing: 0.135em;
                    background: linear-gradient(0deg, #75C062, #75C062), linear-gradient(76.82deg, #576265 11.6%, #9EA1A1 31.94%, #576265 38.59%, #848B8A 57.27%, #576265 70.14%, #757A7B 85.34%, #576265 91.31%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    background-blend-mode: color, normal;
                }

                .text {
                    position: relative;
                    top: -30px;
                    @robotoRegular();
                    color: @white;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.08em;
                }
            }
        }
    }

    .block5 {

    }

}
