@import (reference) '../../../assets/styles/styles.less';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 792px;
    background: center/105% no-repeat url('../../../assets/images/jpg/backgroundGrey.jpg');
    padding: 32px 65px 102px 65px;

    .portfolioHeading {
        @furoreRegular();
        font-size: 96px;
        line-height: 110px;
        letter-spacing: 0.135em;
        background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52%, #ffffff 95%),
            linear-gradient(76.82deg, #576265 19%, #576265 15%, #848b8a 29%, #576265 41.49%, #576265 61%, #798184 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-blend-mode: color, overlay, normal;
        text-shadow: #576265;
    }

    .companyList {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        margin-top: 40px;
    }

    .textBtn {
        @textButton();
        display: block;
        margin-top: 20px;
        text-decoration: none;
        transition: all 0.2s ease-in;

        &:hover {
            color: @green1;
        }
    }
}
