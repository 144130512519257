@furoreRegular: {
    font-family: 'Furore', sans-serif;
    font-weight: 400;
};

@captureItRegular: {
    font-family: 'Capture It', sans-serif;
    font-weight: 400;
};

@robotoRegular: {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
};

@robotoLight: {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
};

@robotoMedium: {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
};

@robotoBold: {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
};

@robotoBlack: {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
};

@blurryBackgroundGradient: {
    background: linear-gradient(177.17deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(25px);
};

@textButton: {
    cursor: pointer;
    border: none;
    background: none;
    @furoreRegular();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.08em;
    color: @white;
    margin-left: auto;
    margin-top: auto;
};
