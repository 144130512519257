@import (reference) '../../../assets/styles/styles.less';

.container {
    display: flex;
    position: relative;
    width: 100%;
    height: 650px;
    background: center/100% no-repeat url('../../../assets/images/jpg/selfEmployedBcg.jpg');
}

.blurredFrame {
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/blurryBcgFrame.png');
    width: 50%;
    height: 650px;
    padding: 171px 0 98px 66px;

    .mainText {
        @furoreRegular();
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.045em;
        color: @grey7;
        margin-bottom: 20px;
    }

    .secondaryText {
        @robotoMedium();
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.045em;
        color: @grey7;
        white-space: pre-wrap;
    }

    .featuresList {
        @robotoMedium();
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.045em;
        color: @white;
        margin-top: 40px;
        padding-right: 48px;
        padding-left: 36px;
        white-space: pre-wrap;

        .feature {
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                position: absolute;
                width: 26px;
                height: 26px;

                left: -38px;

                background: @ballGradient;
                background-blend-mode: color, overlay, normal;
                border-radius: 50%;
            }
        }
    }
}

.rightTextWrapper {
    flex: 0 1 50%;
    align-self: flex-end;
    padding: 0 60px 42px 60px;
    .rightText {
        @robotoMedium();
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.045em;
        color: @grey7;
        white-space: pre-wrap;
    }

    .rightTextGreen {
        @captureItRegular();
        color: rgba(24, 119, 0, 1);
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 0.045em;
    }
}
