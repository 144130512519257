@import '../../../assets/styles/styles.less';

.orderPage {
    h2 {
        color: #333;
        font-family: Furore;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.44px;
        @media all and (max-width: @mobileWidth) {
            display: none;
        }
    }
    h3 {
        color: #333;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 30px;
        margin-bottom: 30px;
        @media all and (max-width: @mobileWidth) {
            color: #333;
            font-family: Furore;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    h4 {
        color: #7d7d7d;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }

    .block0 {
        height: 141px;
        background-color: #2a2b2d;
        @media all and (max-width: @mobileWidth) {
            height: 30px;
        }
    }
    .block1 {
        background-color: #ebebeb;
        height: 826px;
        padding: 50px 65px 0 65px;

        @media all and (max-width: @mobileWidth) {
            height: 877px;
            padding: 0 0 0 0;
        }

        .posPicWrapper {
            height: 100%;
            width: 100%;
            //background: url('../../../assets/images/png/posGroup1.png') bottom 20px right 66px no-repeat;
        }
    }

    .block2 {
        height: 633px;
        background: #f7f7f7 url('../../../assets/images/png/orderTerminal.png') center right 0 no-repeat;
        margin-top: 40px;
        padding: 0 0 0 30px;
        @media all and (max-width: @mobileWidth) {
            background: #fff;
            height: 100%;

            padding: 0 30px 0 30px;
        }
    }
    .optionsWrapper {
        height: 210px;
        width: 62%;
        display: block;
        @media all and (max-width: @mobileWidth) {
            height: 340px;
            width: 100%;
        }
    }
    .optionsBlock {
        list-style: none;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media all and (max-width: @mobileWidth) {
            flex-direction: row;
        }
    }

    .countWrapper {
        width: 294px;
        height: 50px;
        flex-shrink: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        @media all and (max-width: @mobileWidth) {
            margin-top: 32px;
            width: 254px;
        }
    }

    .countHeader {
        margin: auto 12px auto 0;

        @media all and (max-width: @mobileWidth) {
            display: inline-block;
            width: 100%;
        }
    }

    .inputCount {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        text-align: center;
    }

    .contactsWrapper {
        //height: 210px;
        width: 64%;
        display: block;
        margin-top: 10px;
        @media all and (max-width: @mobileWidth) {
            width: 100% !important;
        }
    }
    .contactsInput {
        height: 50px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin-right: 20px;
        margin-top: 20px;
        text-indent: 10px;

        @media all and (max-width: @mobileWidth) {
            width: 100% !important;
            height: 48px;
            display: flex;
            width: 280px;
            padding: 15px 19px 14px 13px;
            align-items: center;
        }
    }
    .contactsButton {
        width: 205px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 10px;
        background: linear-gradient(0deg, #75c062 0%, #75c062 100%), linear-gradient(333deg, rgba(255, 255, 255, 0) 0%, #fff 100%),
            linear-gradient(
                80deg,
                #576265 0%,
                #576265 10.25%,
                #848b8a 22.06%,
                #576265 37.5%,
                #576265 50.52%,
                #798184 71.43%,
                #9ea1a1 85.71%,
                #757a7b 100%
            );
        background-blend-mode: color, overlay, normal;

        color: #fff;
        text-align: center;
        font-family: Furore;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
        letter-spacing: 0.64px;
    }

    .brtContainer {
        width: 100%;
        @media all and (max-width: @mobileWidth) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .sendButton {
        margin-top: 30px;
        @media all and (max-width: @mobileWidth) {
            position: relative;
        }
    }

    .block3 {
        height: 633px;
        background: #f7f7f7 url('../../../assets/images/png/repeatOrderWoman.png') right 0 no-repeat;
        margin-top: 40px;
        padding: 0 0 0 30px;

        @media all and (max-width: @mobileWidth) {
            height: 763px;
            position: relative;
            top: 20px;
            padding: 0;
            padding-left: 10px;
            padding-top: 10px;
            background: #f7f7f7 url('../../../assets/images/png/orderTerminal.png') center center no-repeat;
        }
    }

    .repeatEmptyBlock {
        height: 425px;
        width: 100%;

        @media all and (max-width: @mobileWidth) {
            display: none;
        }
    }

    .repeatText {
        color: #333;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 75% */
        @media all and (max-width: @mobileWidth) {
            color: #333;
            font-family: Furore;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .repeatTextSmall {
        color: #333;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 75% */
        @media all and (max-width: @mobileWidth) {
            color: #333;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 112.5% */
        }
    }

    .repeatButtonContainer {
        margin-top: 41px;
        @media all and (max-width: @mobileWidth) {
            width: 100%;
            margin-top: auto;
            display: flex;
            justify-content: center;
        }
    }
}

.repeatButton {
    @media all and (max-width: @mobileWidth) {
        position: relative;
        top: 610px;
    }
}
