@import (reference) '../../../assets/styles/styles.less';

.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200000;

    .fader {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.4;
    }

    .modalContent {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 985px;
        max-height: 248px;
        @blurryBackgroundGradient();
        background-image: url('../../../assets/images/png/blurryModalFrame.png');
    }
}

.wrapper {
    display: flex;
    align-items: center;
    padding: 30px 30px 0 40px;
}

.imgWrapper {
    height: 100%;
}

.textWrapper {
    text-align: left;
    margin-left: 40px;
}

.title {
    @robotoRegular();
    font-size: 24px;
    line-height: 28px;
    color: @white;
    margin-bottom: 20px;
    white-space: pre-wrap;
}

.text {
    @robotoRegular();
    font-size: 16px;
    line-height: 20px;
    color: @white;
}

.closeBtn {
    background: none;
    border: none;
    position: absolute;
    width: 21px;
    height: 21px;
    top: 18px;
    right: 18px;
    background-image: url('../../../assets/images/png/close.png');
    transition: all 0.5 ease-in;

    &:hover {
        background-image: url('../../../assets/images/png/closeHover.png');
    }
}
