@import (reference) '../../../assets/styles/styles.less';

.roboto {
    font-family: 'Roboto', sans-serif;
}

.listItem {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 30px;
    position: relative;
    padding-left: 36px;

    &:before {
        content: '';
        width: 26px;
        height: 26px;
        display: block;
        position: absolute;
        left: 0;
        top: -1px;
        background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
            linear-gradient(
                76.82deg,
                #576265 11.6%,
                #576265 19.77%,
                #848b8a 29.18%,
                #576265 41.49%,
                #576265 51.87%,
                #798184 68.54%,
                #9ea1a1 79.92%,
                #757a7b 91.31%
            );
        background-blend-mode: color, overlay, normal;
        border-radius: 20px;
    }
}

.orderWrapper {
    .block1 {
        display: flex;
        justify-content: space-between;
        height: 1010px;
        padding: 170px 0 0 66px;
        background: url('../../../assets/images/png/womanWithRectangle.png') top 0px right no-repeat;

        .textBlock1,
        .textBlock2 {
            width: 42.4%;

            .listItem {
                @robotoRegular();
                font-size: 20px;
                line-height: 24px;
                color: @white;

                .listHead {
                    @furoreRegular();
                    font-size: 20px;
                    line-height: 23px;
                    color: #d9d9d9;
                    display: block;
                    margin-bottom: 4px;
                }
            }
        }

        .textBlock1 {
            h3 {
                @furoreRegular();
                font-size: 32px;
                line-height: 37px;
                letter-spacing: 0.045em;
                color: #d9d9d9;
                margin-bottom: 20px;
            }

            h4 {
                @furoreRegular();
                font-size: 24px;
                line-height: 28px;
                letter-spacing: 0.045em;
                color: #d9d9d9;
                margin: 25px 0;
            }

            p {
                @robotoRegular();
                font-size: 20px;
                line-height: 23px;
                color: @white;
            }
        }

        .textBlock2 {
            margin-top: 500px;
        }
    }

    .block2 {
        padding: 66px 65px 0 65px;
        height: 990px;
        background: #414141;
        color: @white;

        h3 {
            @furoreRegular();
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.045em;
        }

        .cardsPlace {
            margin-top: 39px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .card {
                box-sizing: border-box;
                width: 394px;
                min-height: 373px;
                margin-bottom: 43px;
                padding: 9px;
                background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
                    linear-gradient(
                        76.82deg,
                        #576265 11.6%,
                        #576265 19.77%,
                        #848b8a 29.18%,
                        #576265 41.49%,
                        #576265 51.87%,
                        #798184 68.54%,
                        #9ea1a1 79.92%,
                        #757a7b 91.31%
                    );
                background-blend-mode: color, overlay, normal;

                .cardContent {
                    text-align: center;
                    padding: 10px;
                    height: 100%;
                    background: linear-gradient(177.17deg, #6b6b6b 0%, #434343 100%);

                    .title {
                        @furoreRegular();
                        font-size: 20px;
                        line-height: 23px;
                        text-align: center;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    p {
                        @robotoRegular();
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.14em;
                        color: #ebecec;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .block3 {
        height: 660px;
        color: @black;
        padding: 65px;
        background: linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
            linear-gradient(76.82deg, #576265 11.6%, #9ea1a1 29.87%, #afafaf 51.04%, #798184 77.19%, #757a7b 90.48%);
        background-blend-mode: overlay, normal;

        h3 {
            @furoreRegular();
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.045em;
            color: @black;
        }

        h5 {
            @furoreRegular();
            font-size: 20px;
            line-height: 23px;
            letter-spacing: 0.045em;
            margin-bottom: 6px;
        }

        p {
            @robotoRegular();
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.14em;
        }

        .columnsContent {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;

            .first {
                text-align: right;
                width: 540px;

                .column2 {
                    margin-top: 140px;
                }
            }

            .second {
                text-align: left;

                .circleNumber {
                    @furoreRegular();
                    font-size: 48px;
                    line-height: 55px;
                    letter-spacing: 0.045em;
                    width: 100px;
                    height: 100px;
                    color: @white;
                    background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
                        linear-gradient(
                            76.82deg,
                            #576265 11.6%,
                            #576265 19.77%,
                            #848b8a 29.18%,
                            #576265 41.49%,
                            #576265 51.87%,
                            #798184 68.54%,
                            #9ea1a1 79.92%,
                            #757a7b 91.31%
                        );
                    background-blend-mode: color, overlay, normal;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 20px;
                }
            }

            .last {
                width: 540px;

                .aside1 {
                    margin-top: 116px;
                }
                .aside2 {
                    margin-top: 120px;
                }
            }
        }
    }

    .block5 {
        height: 648px;
    }
}
