@import (reference) '../../../assets/styles/styles.less';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 80px;
    &:hover {
        padding: 30px 30px;
    }
}

.big {
    width: 610px;
    height: 245px;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/companyBlurryFrameBig.png');

    &:hover {
        background-image: url('../../../assets/images/png/bigGreenFrame.png');
    }
}

.small {
    width: 312px;
    height: 245px;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/companyBlurryFrameSmall.png');

    &:hover {
        background-image: url('../../../assets/images/png/smallGreenFrame.png');
    }
}

.companies {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .imgWrapper {
        margin: auto;
    }
}

.hoverText {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    color: @white;
    white-space: pre-wrap;
}
