@import (reference) '../../../assets/styles/styles.less';

.fpGateInfo {
    width: 100%;
    height: 364px;
    background: #5c5c5c;
    padding-top: 171px;
    padding-left: 65px;
}

.heading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.045em;
    color: @grey7;
    margin-bottom: 20px;
}

.text {
    width: 995px;
    height: 96px;
    @robotoMedium();
    line-height: 28px;
    font-size: 24px;
    color: @grey7;
    letter-spacing: 0.045em;

    span {
        @robotoRegular();
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.045em;
        text-align: left;
    }
}

.fpGateFeatures {
    height: 716px;
    background: #414141;
    padding: 40px 67px 0 66px;
}

.fpGateFeaturesHeading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.135em;
    text-align: left;
    color: @grey5;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px 80px;
    align-items: center;
    margin-top: 42px;
    position: relative;

    .listItem {
        text-align: center;

        &:first-child {
            top: -12px;
            position: relative;
        }

        &:nth-child(2) {
            top: -9px;
            position: relative;
        }

        &:nth-child(4) {
            top: 15px;
            position: relative;
        }

        .mainText {
            @robotoRegular();
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.045em;
            color: @white;
            white-space: pre-wrap;
        }

        .secondaryText {
            @robotoRegular();
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.045em;
            color: @white;
            white-space: pre-wrap;
            padding-top: 6px;
        }

        .fpGateImg {
            margin-bottom: 20px;
        }
    }
}

.tech {
    padding: 50px 67px 0 66px;
    height: 390px;
    background: #999999;
}

.techHeading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.135em;
    color: #f3f0f0;
}

.stackList {
    display: flex;
    align-items: center;
    gap: 96px;
    padding-left: 75px;
    margin-top: 45px;

    .stackListItem {
        &:first-child {
            margin-top: auto;
        }
    }
}

.stackImg {
    filter: saturate(0);
    transition: all 0.5s;

    &:hover {
        filter: none;
    }
}
