@import (reference) '../../../assets/styles/styles.less';

.container {
    display: flex;
    position: relative;
    width: 100%;
    height: 677px;
    background: center/100% no-repeat url('../../../assets/images/png/aboutBackground.png');
}

.blurredFrame {
    position: relative;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/aboutBlurBackground.png');
    width: 700px;
    height: 677px;
}

.mainText {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.135em;
    color: @grey9;
    margin-top: 258px;
    margin-left: 67px;
}

.secondaryText {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.135em;
    color: @grey10;
    width: 579px;
    height: 161px;
    margin-top: 20px;
    margin-left: 67px;
}

.rightFrame {
    position: relative;
    width: 700px;
    height: 677px;
}

.greenText {
    margin-top: 591px;
    @furoreRegular();
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.135em;
    background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
        linear-gradient(
            76.82deg,
            #576265 11.6%,
            #576265 19.77%,
            #848b8a 29.18%,
            #576265 41.49%,
            #576265 51.87%,
            #798184 68.54%,
            #9ea1a1 79.92%,
            #757a7b 91.31%
        );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-blend-mode: color, overlay, normal;
}
