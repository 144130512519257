@import (reference) '../../../assets/styles/styles.less';

.footer {
    width: 1400px;
    padding-top: 40px;
    background: @grey3;
}

.footerFrame {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footerLeftPart {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.11em;
    color: @white;
    margin-left: 66px;
}

.footerPhone {
    margin-top: 43px;
}

.footerMail {
    margin-top: 20px;
    margin-bottom: 166px;
}

.footerCopyRight {
    margin-bottom: 20px;
    color: #a4a4a4;
}

.footerCenterPart {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    color: @white;

    display: flex;
    justify-content: flex-start;
    gap: 140px;
}

.footerCaption {
    @furoreRegular();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.08em;
    color: @white;
}

.footerLine {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    color: @white;
    margin-top: 20px;
    display: block;
    margin-top: 20px;

    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.singleLink {
    font-family: 'Furore', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    color: #ffffff;
}
