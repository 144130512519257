@import (reference) '../../../assets/styles/styles.less';

.container {
    width: 100%;
    background: @grey3;
    padding: 60px 69px 0px 66px;
    height: 450px;
    background: linear-gradient(
        84.92deg,
        #576265 8.71%,
        #9ea1a1 30.99%,
        #848b8a 67.95%,
        #576265 80.41%,
        #576265 115.36%,
        #757a7b 128.53%,
        #576265 138.23%
    );

    .heading {
        @furoreRegular();
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.135em;
        color: @white;
    }

    .listWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .list {
        @robotoMedium();
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.045em;
        color: @white;
        margin-top: 40px;
        padding-right: 48px;
        padding-left: 36px;
        white-space: pre-wrap;

        .listItem {
            width: 576px;
            height: 46px;
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                position: absolute;
                width: 26px;
                height: 26px;
                left: -38px;
                background: @ballGradient;
                background-blend-mode: color, overlay, normal;
                border-radius: 50%;
            }
        }
    }
}
