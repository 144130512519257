@FontPathFurore: '/src/assets/fonts/furore';
@FontPathRoboto: '/src/assets/fonts/roboto';
@FontPathCaptureIt: '/src/assets/fonts/captureIt';

@font-face {
    font-family: 'Capture It';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('@{FontPathCaptureIt}/captureIt_regular.ttf?v=0.01') format('truetype');
}


@font-face {
    font-family: 'Furore';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("@{FontPathFurore}/furore_regular.ttf?v=0.01") format("truetype");
}

@font-face {
    font-family: 'Roboto';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("@{FontPathRoboto}/Roboto-Light.ttf?v=0.01") format("truetype");
}

@font-face {
    font-family: 'Roboto';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("@{FontPathRoboto}/Roboto-Regular.ttf?v=0.01") format("truetype");
}

@font-face {
    font-family: 'Roboto';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("@{FontPathRoboto}/Roboto-Medium.ttf?v=0.01") format("truetype");
}

@font-face {
    font-family: 'Roboto';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("@{FontPathRoboto}/Roboto-Bold.ttf?v=0.01") format("truetype");
}

@font-face {
    font-family: 'Roboto';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("@{FontPathRoboto}/Roboto-Black.ttf?v=0.01") format("truetype");
}


