@import (reference) '../../../assets/styles/styles.less';

.portfolio {
    width: 1400px;
    background: linear-gradient(180deg, #5c5c5c 0%, #ffffff 100%);
}

@media all and (max-width: @mobileWidth) {
    .portfolio {
        width: 100%;
        height: fit-content;
        background: #9ea1a1;
        margin: auto;
    }
}
