@import (reference) '../../../assets/styles/styles.less';

.button {
    background: none;
    box-sizing: border-box;
    display: inline-flex;
    text-transform: none;
    font-weight: 500;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    justify-content: center;
    position: relative;
    align-items: center;
    vertical-align: middle;

    &.main {
        @blurryBackgroundGradient();
        @furoreRegular();
        color: @white;
        background-image: url('../../../assets/images/svg/mainButton.svg');
        background-position: center;
        width: 306px;
        height: 70px;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.08em;
        padding: 24px 34px;

        &:hover {
            background-image: url('../../../assets/images/svg/mainButtonHover.svg');
        }
    }

    &.secondary {
        @furoreRegular();
        color: @white;
        background-position: center;
        width: 263px;
        height: 42px;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.08em;
        background-image: url('../../../assets/images/svg/secondaryButton.svg');
        background-position: center;
        transition: all 0.5s ease-in;
        padding: 12px 76px;

        &:hover {
            background-image: url('../../../assets/images/svg/secondaryButtonHover.svg');
        }
    }

    &.rounded {
        @furoreRegular();
        color: @white;
        background-position: center;
        height: 50px;
        width: 205px;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.08em;
        background-image: url('../../../assets/images/svg/roundedBtn.svg');
        background-position: center;
        transition: all 0.5s ease-in;
        padding: 12px 76px;
        border-radius: @borderRadius;
        &:hover {
            background-image: url('../../../assets/images/svg/roundedBtnHover.svg');
        }
    }

    &.long {
        @furoreRegular();
        color: @white;
        background-position: center;
        height: 50px;
        width: 472px;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.08em;
        background-image: url('../../../assets/images/svg/longBtn.svg');
        background-position: center;
        transition: all 0.5s ease-in;
        padding: 12px 76px;

        &:hover {
            background-image: url('../../../assets/images/svg/longBtnHover.svg');
        }
    }
}

@media all and (max-width: @mobileWidth) {
    .button {
        &.main {
            height: 45px;
            width: 278px;
            background-image: url('../../../assets/images/png/mainButtonMobile.png');
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.035em;
            padding: 0;
            color: #fbfbfb;
            transition: all 0.5s ease-in;
            &:hover {
                background-image: url('../../../assets/images/png/mainButtonMobileHover.png');
            }
        }

        &.secondary {
            height: 41px;
            width: 253px;
            background-image: url('../../../assets/images/png/mobileBtnSecondary.png');
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.035em;
            padding: 0;
            color: #fbfbfb;
            transition: all 0.5s ease-in;
        }
        &.long {
            height: 40px;
            width: 253px;
            left: 0px;

            background-image: url('../../../assets/images/png/longBtnMobile.png');
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.035em;
            padding: 0;
            color: #fbfbfb;
            transition: all 0.5s ease-in;
        }
    }
}
