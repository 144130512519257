@import (reference) '../../../assets/styles/styles.less';

.container {
    width: 100%;
    height: 648px;
    background: center/100% no-repeat url('../../../assets/images/jpg/progers.jpg');
    padding: 81px 65px 102px 65px;

    &.cases {
        background: center/100% no-repeat url('../../../assets/images/jpg/casesBcgForm.jpg');
    }

    &.gpay {
        background: center/100% no-repeat url('../../../assets/images/jpg/gpayBcgForm.jpg');
    }

    &.souz {
        background: center/100% no-repeat url('../../../assets/images/jpg/gpayBcgForm.jpg');
    }

    &.equiring {
        background: center/100% no-repeat url('../../../assets/images/jpg/equiringForm.jpg');
    }

    &.orderCode {
        background: center/100% no-repeat url('../../../assets/images/jpg/order.jpg');
    }

    &.hands {
        background: center/100% no-repeat url('../../../assets/images/jpg/formBcgHands.jpg');
    }

    &.consulting {
        background: center/100% no-repeat url('../../../assets/images/jpg/consultingForm.jpg');
    }

    .formHeading {
        @furoreRegular();
        font-size: 64px;
        line-height: 74px;
        letter-spacing: 0.135em;
        color: #e8e8e8;
    }

    .formContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
    }
}
