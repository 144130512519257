@import (reference) '../../../assets/styles/styles.less';

.container {
    position: relative;
    width: 100%;
    height: 582px;
    background: @grey12;

    .aboutWhyHeading {
        font-family: 'Furore';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.135em;
        color: #524e4e;

        padding-top: 40px;
        padding-left: 65px;
        width: 100%;
        height: 46px;
    }

    .centerBlock {
        background: #c6c6c6;
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-top: 93px;

        .columnItem {
            display: flex;
            align-items: center;
            flex-direction: column;
            //justify-content: center;

            .heading {
                @furoreRegular();
                text-align: center;
                max-width: 365px;
                font-size: 24px;
                line-height: 28px;
                letter-spacing: 0.135em;
                color: #302d2d;
                margin-top: 27px;
            }
            .text {
                @robotoRegular();
                font-size: 20px;
                line-height: 23px;
                text-align: center;
                letter-spacing: 0.135em;
                color: #000000;
                max-width: 410px;
                margin-top: 16px;
            }
        }
    }
}
