@import (reference) '../../../assets/styles/styles.less';

.container {
    position: relative;
    width: 100%;
    height: 622px;
    background: @grey3;
    padding: 32px 66px 98px 65px;

    .servicesHeading {
        @furoreRegular();

        font-size: 96px;
        line-height: 110px;
        letter-spacing: 0.135em;

        background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
            linear-gradient(
                76.82deg,
                #576265 11.6%,
                #576265 19.77%,
                #848b8a 29.18%,
                #576265 41.49%,
                #576265 51.87%,
                #798184 68.54%,
                #9ea1a1 79.92%,
                #757a7b 91.31%
            );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        background-blend-mode: color, overlay, normal;
    }

    .cards {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media all and (max-width: @mobileWidth) {
    .container {
        width: 100%;
        padding: 0;
        height: fit-content;
        padding-bottom: 40px;

        .servicesHeading {
            font-size: 20px;
            line-height: 23px;
            margin: 30px 0 0 20px;
            letter-spacing: 0.135em;
        }

        .cards {
            flex-direction: column;
            margin-top: 20px;
        }
    }
}
