@import (reference) '../../../assets/styles/styles.less';

.container {
    position: relative;
    display: flex;
    width: 100%;
    height: 620px;
    background: linear-gradient(0.19deg, #5c5c5c 6.71%, #d9d9d9 73.93%);

    .rightBlock {
        margin-left: 60px;
    }
    .mainText {
        @furoreRegular();
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.135em;
        color: @grey9;
        margin-top: 191px;
    }

    .secondaryText {
        @robotoRegular();
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.135em;
        color: @grey9;
        margin-top: 30px;
        width: 579px;
    }

    .aboutHockey {
        margin-left: 66px;
        margin-top: 105px;
    }
}
