@import (reference) '../../../assets/styles/styles.less';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.companies {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    height: 101px;
    .imgWrapper {
        margin: auto;
    }
}
