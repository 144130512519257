@import (reference) '../../../assets/styles/styles.less';

.footer {
    width: 1400px;
    padding-top: 40px;
    background: @grey3;
}

.footerFrame {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footerLeftPart {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.11em;
    color: @white;
    margin-left: 66px;
}

.footerPhone {
    margin-top: 43px;
}

.footerMail {
    margin-top: 20px;
    margin-bottom: 166px;
}

.footerCopyRight {
    margin-bottom: 20px;
    color: #a4a4a4;
}

.footerCenterPart {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    color: @white;

    display: flex;
    justify-content: flex-start;
    gap: 140px;
}

.footerCaption {
    @furoreRegular();
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.08em;
    color: @white;
}

.footerLine {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    color: @white;
    margin-top: 20px;
    display: block;
    margin-top: 20px;

    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.singleLink {
    font-family: 'Furore', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    color: #ffffff;
}

@media all and (max-width: @mobileWidth) {
    .footer {
        width: 100%;
        padding: 0;
    }

    .footerFrame {
        flex-direction: column;
    }

    .footerLeftPart {
        width: 100%;
        height: 106px;
        display: flex;
        align-items: center;
        margin: 0;
        justify-content: space-between;
        padding: 26px 20px 29px 20px;
    }

    .footerCenterPart {
        flex-direction: column;
        width: 100%;
        gap: 0px;
    }

    .footerPhone {
        margin: 0;
    }

    .footerMail {
        margin: 0;
        margin-top: 8px;
    }

    .footerCaption {
        height: 50px;
        position: relative;
        background: #2a2b2d;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    }

    .footerCaptionHead,
    .singleLink {
        font-family: 'Furore';
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.08em;
        color: #ffffff;
        padding-top: 16px;
        padding-left: 20px;
        height: 50px;
    }

    .ddTop {
        position: relative;
    }

    .footerIcon {
        position: absolute;
        z-index: 499;
        right: 20px;
        top: 33%;
        transition: all 0.2s;

        &.active {
            transform: rotate(180deg);
        }
    }

    .footerLine {
        display: block;
        position: relative;
        z-index: 500;
        height: 50px;
        margin: 0;
        background: #2a2b2d;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        padding-top: 16px;
        padding-left: 20px;
    }

    .dd {
        width: 100%;
    }

    .footerCopyRight {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #a4a4a4;
        padding-top: 20px;
        padding-left: 20px;
    }
}
