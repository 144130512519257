@import (reference) '../../../assets/styles/styles.less';

.equiringHead {
    width: 1400px;
    height: 1010px;
    background: #576265;
    padding: 171px 65px 55px 65px;
    display: flex;
    flex-wrap: wrap;
}

.textContainer {
    width: 50%;
}

.heading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.045em;
    color: @grey7;
}

.secondaryHeading {
    @furoreRegular();
    color: @grey7;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    margin-top: 20px;
}

.text {
    margin-top: 12px;

    &:first-of-type {
        height: 69px;
        width: 548px;
    }

    &:nth-of-type(2) {
        margin-top: 20px;
        height: 322px;
        width: 564px;
    }
}

.text {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.135em;
    color: @grey7;
}

.list {
    display: flex;
}

.listItem {
    text-align: center;
    margin-right: 48px;

    &:first-of-type {
        height: 181px;
        width: 230px;
    }

    &:nth-of-type(2) {
        height: 161px;
        width: 243px;
    }
    &:nth-of-type(3) {
        height: 171px;
        width: 219px;
        position: relative;
        top: 10px;

        img {
            position: relative;
            top: -10px;
            left: -6px;
        }
    }
    &:nth-of-type(4) {
        height: 183px;
        width: 186px;
    }
    &:nth-of-type(5) {
        height: 157px;
        width: 200px;
        position: relative;
        top: 10px;
    }
}

.listText {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.135em;
    color: @white;
    white-space: pre-wrap;
    position: relative;
    top: 10px;
}

.imageContainer {
    padding-top: 10px;
    position: relative;
    left: -20px;
    width: 50%;
}

.howItWorksContainer {
    width: 1400px;
    height: 470px;
    background: linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
        linear-gradient(76.82deg, #576265 11.6%, #576265 19.77%, #848b8a 29.18%, #576265 41.49%, #798184 68.54%, #9ea1a1 79.92%, #757a7b 91.31%);
    background-blend-mode: overlay, normal;
    padding: 60px 0 0 65px;
}

.howItWorksHeading {
    @furoreRegular();
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    margin-bottom: 20px;
    color: #0e0e0e;
}

.numsText {
    @robotoRegular();
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.125em;
    color: #0e0e0e;
}

.numsBlock {
    position: relative;
}

.nums {
    top: 20px;
    position: absolute;
}

.numsText {
    position: relative;

    &:first-of-type {
        height: 69px;
        width: 332px;
        top: 2px;
        left: 5px;
    }

    &:nth-of-type(2) {
        height: 92px;
        width: 273px;
        top: 2px;
        left: 105px;
    }

    &:nth-of-type(3) {
        height: 69px;
        width: 435px;
        left: 212px;
        top: 1px;
    }

    &:nth-of-type(4) {
        height: 92px;
        width: 308px;
        left: 6px;
        top: 68px;
    }

    &:nth-of-type(5) {
        height: 92px;
        width: 336px;
        top: 66px;
        left: 120px;
    }

    &:nth-of-type(6) {
        height: 23px;
        width: 433px;
        top: 66px;
        left: 170px;
    }
}

.numsTextWrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    top: 58px;
    left: 20px;
}
