@import (reference) '../../../assets/styles/styles.less';

.container {
    width: 100%;
    height: 1012px;
    background: center / 100% no-repeat url('../../../assets/images/jpg/earth.jpg');
    padding: 40px 65px;

    .productsHeading {
        @furoreRegular();
        height: 132px;
        width: 659px;
        font-size: 96px;
        line-height: 110px;
        letter-spacing: 0.135em;
        color: #b7b7b7;
        backdrop-filter: blur(25px);
    }

    .productCards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
        gap: 24px;
    }
}

@media all and (max-width: @mobileWidth) {
    .container {
        background: #2a2b2d;
        height: max-content;
        padding: 0;
        padding-bottom: 40px;

        .productsHeading {
            font-size: 20px;
            line-height: 23px;
            letter-spacing: 0.135em;
            color: #ffffff;
            height: 23px;
            width: 138px;
            margin-left: 20px;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .mobileProducts {
            margin-left: auto;
            margin-right: auto;
            width: 280px;
        }

        .mobileProductsItem {
            cursor: pointer;
            margin-top: 27px;
        }

        .link {
            text-decoration: none;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.135em;
            color: #ffffff;
        }

        .linkBtn {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
    }
}
