@import (reference) '../../../assets/styles/styles.less';

.container {
    padding: 181px 60px 40px 60px;

    .portfolioHeading {
        @furoreRegular();
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.135em;
        color: @grey5;
        margin-bottom: 30px;
    }
}

.portfolioList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media all and (max-width: @mobileWidth) {
    .container {
        width: 280px;
        padding: 0;
        padding-bottom: 40px;
        padding-top: 87px;

        margin: auto;

        .portfolioHeading {
            display: block;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.24em;
            color: #ffffff;
            margin-bottom: 20px;
        }
    }

    .portfolioList {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}
