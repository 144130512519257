@import (reference) '../../../assets/styles/styles.less';

.container {
    width: 100%;
    height: 483px;
    background: @grey3;
    padding: 60px 69px 0px 66px;

    .conceptHeading {
        @furoreRegular();
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.135em;
        color: @white;
    }

    .conceptList {
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        // gap: 30px;
        // align-items: center;
        display: flex;
        justify-content: center;
        gap: 15px;

        margin-top: 42px;
        position: relative;
        justify-content: center;

        .conceptListItem {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 415px;

            .mainText {
                @robotoRegular();
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.045em;
                color: @grey7;
                white-space: pre-wrap;
            }

            .secondaryText {
                @robotoRegular();
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.045em;
                color: @grey7;
                white-space: pre-wrap;
            }

            .conceptImg {
                margin-bottom: 18px;
            }
        }
    }
}
