@import '../assets/styles/styles.less';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    @furoreRegular();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: @backgroundColor;
    width: 100%;
}
