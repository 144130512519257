@import (reference) '../../../assets/styles/styles.less';
.header {
    //width: 100%;
    position: absolute;
    z-index: 1000;
    width: 1400px;
    height: 141px;
    //box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 14px;
}

.headerFrame {
    //width: @middleWidth;
    width: 1400px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    box-sizing: border-box;
}

.headerNav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 67px;
}

.headerLogo {
    //display: inline-block;
    width: 151px;
    height: 81px;
}

.headerLanguageRight {
    align-items: center;
    display: flex;
    justify-items: right;
    margin-right: 65px;
}

@media all and (max-width: @mobileWidth) {
    .header {
        width: 100%;
        height: 57px;
    }

    .headerFrame {
        width: 100%;
        padding: 10px 22px 0 21px;
    }

    .headerLogo {
        //display: inline-block;
        width: 86px;
        height: 47px;
    }

    .headerNav {
        justify-content: space-between;
        margin-left: 0;
        width: 100%;
    }
}
