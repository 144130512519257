.checkbox {
    background: none;
    box-sizing: border-box;
    display: inline-flex;
    text-transform: none;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    justify-content: center;
    position: relative;
    align-items: center;
    vertical-align: middle;

    color: #333;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */

    margin-left: 11px;
}

.lineOption {
    height: 24px;
    margin-bottom: 20px;
    page-break-inside: avoid;
    break-inside: avoid;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
