@import (reference) '../../../assets/styles/styles.less';

.techSupportHead {
    width: 1400px;
    height: 776px;
    background: #414141;
    position: relative;
    display: flex;

    flex-wrap: wrap;
}

.textContainer {
    width: 700px;
    height: 770px;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/techSupportBlurFrame.png');
    padding: 161px 0 43px 66px;
    position: relative;
    z-index: 2;
}

.heading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.045em;
    color: @grey7;
}

.secondaryHeading {
    @furoreRegular();
    color: @grey7;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    margin-top: 30px;
}

.text {
    margin-top: 20px;

    &:first-of-type {
    }

    &:nth-of-type(2) {
        height: 115px;
        width: 595px;
    }
}

.text {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.135em;
    color: @grey7;
    white-space: pre-wrap;
}

.techSupportList {
    padding-left: 40px;
    padding-top: 20px;
}

.techSupportItem {
    position: relative;
    margin-bottom: 10px;
    white-space: pre-wrap;
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.135em;
    color: @grey7;

    &::before {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        left: -38px;
        background: @ballGradient;
        background-blend-mode: color, overlay, normal;
        border-radius: 50%;
    }
}

.techSupportVariants {
    width: 1400px;
    height: 564px;
    background: linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
        linear-gradient(76.82deg, #576265 19.77%, #576265 41.49%, #576265 55.19%, #798184 68.54%, #9ea1a1 79.92%, #757a7b 91.31%);
    background-blend-mode: overlay, normal;
    padding: 65px 0 0 65px;
}

.techSupportVariantsHeading {
    @furoreRegular();
    color: #2a2b2d;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
}

.list {
    display: flex;
    margin-top: 30px;
}

.listItem {
    text-align: center;
    margin-right: 20px;
    position: relative;

    &:first-of-type {
        width: 284px;
        height: 146px;

        .listTextUp {
            white-space: nowrap;
        }
    }

    &:nth-of-type(2) {
        width: 309px;
        height: 151px;
        top: 10px;
    }
    &:nth-of-type(3) {
        width: 310px;
        height: 166px;
        top: 22px;
        img {
            position: relative;
            top: -10px;
        }
    }
    &:nth-of-type(4) {
        width: 322px;
        height: 171px;
        top: 3px;
    }
}

.listTextUp {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.135em;
    color: #000000;
    white-space: pre-wrap;
    margin-top: 10px;
}

.listText {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.135em;
    white-space: pre-wrap;
    margin-top: 10px;
    text-align: center;
    color: #000000;
}

.imageContainer {
    position: relative;
    right: 25px;
    top: 166px;
    width: 50%;
    z-index: 0;
}
