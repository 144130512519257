@import (reference) '../../../assets/styles/styles.less';

.container {
    height: 300px;
    width: 674px;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/blurryFormFrame.png');
    -webkit-box-shadow: 4px 4px 15px 11px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 4px 4px 15px 11px rgba(255, 255, 255, 0.3);
    box-shadow: 4px 4px 15px 11px rgba(255, 255, 255, 0.3);
    padding: 30px;

    &.submitted {
        height: 202px;
    }
}

.formFields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    input,
    textarea {
        all: unset;
    }

    input,
    textarea {
        box-sizing: border-box;
        @robotoRegular();
        font-size: 16px;
        line-height: 19px;
        background: @grey5;
        border: 1px solid @green6;
        border-radius: @borderRadius;
        resize: none;
        padding: 10px 10px;
    }

    .textField {
        height: 50px;
        width: 297px;
    }

    .textFieldBig {
        height: 100px;
        width: 614px;
    }
}

.btn {
    margin-left: auto;
}

.submittedForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .onSubmitHead {
        @furoreRegular();
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.06em;
        color: @white;
    }

    .onSubmitText {
        @robotoRegular();
        font-size: 16px;
        line-height: 19px;
        color: @white;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.formHeading {
    display: none;
}

@media all and (max-width: @mobileWidth) {
    .formHeading {
        display: block;
        font-family: 'Furore';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.135em;
        color: #b7b7b7;
        white-space: nowrap;
    }
    .container {
        background-image: url('../../../assets/images/png/bcgFormMobile.png');
        height: 331px;
        width: 280px;
        padding: 10px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .formFields {
        gap: 10px;
        .textField {
            height: 50px;
            width: 253px;
            border-radius: 0;
        }

        .textFieldBig {
            height: 80px;
            width: 254px;
            border-radius: 0;
        }
    }

    .submittedForm {
        .onSubmitHead {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.135em;
            color: #b7b7b7;
        }

        .onSubmitText {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            height: 57px;
            width: 221px;

            color: #ffffff;
        }
    }
}
