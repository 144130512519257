@import (reference) '../../../assets/styles/styles.less';

.container {
    position: relative;
    width: 100%;
    height: 755px;
    //background: lightgray -330.5px -705px / 148.357% 273.289% no-repeat url('../../../assets/images/svg/womanMain2.svg');
    background: center/100% no-repeat url('../../../assets/images/jpg/womanMainSmall.jpg');
}

.eye {
    position: absolute;
    left: 889px;
    top: 254px;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0.1;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.1;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

.eyeFirst {
    animation-delay: 1s;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 3s;
    animation-name: fadeIn;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.eyeSecond {
    animation-delay: 1s;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 1.5s;
    animation-name: fadeIn;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.blurredFrame {
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/blurryBcgFrame.png');
    width: 50%;
    height: 755px;
    padding: 175px 0 98px 66px;

    .mainText {
        @furoreRegular();
        font-size: 58px;
        color: @grey5;
        letter-spacing: 0.24em;
        line-height: 164.6%;
    }

    .secondaryText {
        @robotoLight();
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.095em;
        color: @white;
        margin-top: 20px;
    }
}
.btnContainer {
    position: absolute;
    bottom: 98px;
    left: 68px;
}

@media all and (max-width: @mobileWidth) {
    .container {
        width: 100%;
        height: 423px;
        background: no-repeat url('../../../assets/images/jpg/womanMain.jpg');
        background-position: 53% 50%;
        background-size: 170%;
        position: relative;
    }

    .eye {
        display: none;
    }

    .blurredFrame {
        height: 423px;
        padding: 187px 82px 20px 20px;
        border-right: 3px solid @grey2;

        .mainText {
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.24em;
            color: #ffffff;
        }

        .secondaryText {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.04em;
            color: #ffffff;
            height: 32px;
            min-width: 235px;
        }
    }

    .btnContainer {
        bottom: 20px;

        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }
}
