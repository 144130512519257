@import (reference) '../../../assets/styles/styles.less';

.container {
    padding: 60px 59px 0px 66px;
    width: 1400px;
    height: 704px;

    background: linear-gradient(
        84.92deg,
        #576265 8.71%,
        #9ea1a1 30.99%,
        #848b8a 67.95%,
        #576265 80.41%,
        #576265 115.36%,
        #757a7b 128.53%,
        #576265 138.23%
    );

    .portfolioHeading {
        @furoreRegular();
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.135em;
        color: @white;
        margin-bottom: 40px;
    }
}

.portfolioList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
