@import (reference) '../../../assets/styles/styles.less';

.consultingHead {
    width: 1400px;
    height: 1120px;
    background: #576265;
    display: flex;
}

.textContainer {
    width: 700px;
    height: 1120px;
    @blurryBackgroundGradient();
    background-image: url('../../../assets/images/png/consultingBlurFrame.png');
    padding: 161px 0 0 66px;
}

.heading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.045em;
    color: @grey7;
}

.secondaryHeading {
    @furoreRegular();
    color: @grey7;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    margin-top: 20px;
}

.text {
    margin-top: 20px;

    &:first-of-type {
        height: 115px;
        width: 585px;
    }

    &:nth-of-type(2) {
        height: 198px;
        width: 604px;
    }

    .green {
        @furoreRegular();
        color: rgba(21, 104, 0, 1);
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.135em;
        margin-top: 30px;
    }
}

.text {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.135em;
    color: @grey7;
    white-space: pre-wrap;
}

.consultingList {
    padding-left: 40px;
    padding-top: 20px;
}

.consultingItem {
    position: relative;
    margin-bottom: 15px;
    white-space: pre-wrap;
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.135em;
    color: @grey7;

    &::before {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        left: -38px;
        background: @ballGradient;
        background-blend-mode: color, overlay, normal;
        border-radius: 50%;
    }
}

.imageContainer {
    position: relative;
    top: 159px;
    left: 30px;
}

.offer {
    width: 1400px;
    height: 620px;
    position: relative;
    padding: 60px 0 0 66px;
    background: linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
        linear-gradient(76.82deg, #576265 11.6%, #9ea1a1 29.87%, #afafaf 51.04%, #798184 77.19%, #757a7b 90.48%);
    background-blend-mode: overlay, normal;
}

.greenFrame {
    position: absolute;
    top: -206px;
    right: 0px;
}

.secondaryHeadingBlack {
    @furoreRegular();
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    color: #0e0e0e;
}

.offerList {
    margin-top: 30px;
}

.offerListItem {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;

    &:first-of-type {
        .offerImg {
            position: relative;
            top: -7px;
            left: 5px;
        }
    }

    &:nth-of-type(2) {
        .offerImg {
            position: relative;
            top: -16px;
            left: 10px;
        }
    }

    &:nth-of-type(3) {
        .offerImg {
            position: relative;
            top: -16px;
            left: 8px;
        }
    }
}

.offerText {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.045em;
    color: #000000;
    margin-bottom: 11px;
}

.offerText2 {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.045em;
    color: #000000;
    height: 95px;
    width: 783px;
}
