@import (reference) '../../../assets/styles/styles.less';

.equiringHead {
    width: 1400px;
    height: 1120px;
    background: #576265;
    padding: 171px 65px 55px 65px;
    display: flex;
}

.textContainer {
    width: 50%;
}

.heading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.045em;
    color: @grey7;
}

.secondaryHeading {
    @furoreRegular();
    color: @grey7;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    margin-top: 20px;
}

.text {
    margin-top: 12px;
    &:first-of-type {
        width: 548px;
        height: 92px;
    }

    &:nth-of-type(2) {
        width: 548px;
        left: 66px;
        height: 161px;
    }

    &:nth-of-type(3) {
        height: 69px;
        width: 594px;
    }
}

.text,
.listItem {
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.045em;
    color: @grey7;
}

.list {
    padding-left: 40px;
    padding-top: 18px;
}

.listItem {
    position: relative;
    margin-bottom: 10px;
    white-space: pre-wrap;

    &::before {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        left: -38px;
        background: @ballGradient;
        background-blend-mode: color, overlay, normal;
        border-radius: 50%;
    }
}

.imageContainer {
    padding-top: 50px;
}

.howItWorksContainer {
    height: 450px;
    width: 1400px;
    background: linear-gradient(
        84.92deg,
        #576265 8.71%,
        #848b8a 46.48%,
        #576265 68.07%,
        #9ea1a1 76.17%,
        #576265 115.36%,
        #757a7b 128.53%,
        #576265 138.23%
    );
    padding: 60px 0 0 65px;
}

.howItWorksHeading {
    @furoreRegular();
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    color: @grey7;
    margin-bottom: 20px;
}

.howItWorksText,
.numsText {
    @robotoRegular();
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.045em;
    color: @white;
    white-space: pre-wrap;
}

.howItWorksText {
    height: 38px;
    width: 1224px;
}

.numsBlock {
    position: relative;
}

.nums {
    top: 20px;
    position: absolute;
}

.numsText {
    &:first-of-type {
        height: 152px;
        width: 348px;
    }

    &:nth-of-type(2) {
        height: 132px;
        width: 256px;
    }
    &:nth-of-type(3) {
        height: 95px;
        width: 218px;
        position: relative;
        left: -25px;
    }
    &:nth-of-type(4) {
        height: 38px;
        width: 234px;
        position: relative;
        left: -30px;
        top: -5px;
    }
}

.numsTextWrapper {
    display: flex;
    position: relative;
    gap: 75px;
    top: 58px;
    left: 20px;
}
