@import (reference) '../../../assets/styles/styles.less';

.container {
    position: relative;
    width: 100%;
    height: 483px;
    background: @grey3;
    padding: 60px 0 0 66px;
}

.heading {
    @furoreRegular();
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.135em;
    color: @white;
}

.partnersWrapper {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.partner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageFns {
    height: 153px;
    width: 148px;
    margin-bottom: 32px;
}

.imageTinkoff {
    height: 164px;
    width: 138px;
    margin-bottom: 23px;
}

.text {
    @robotoMedium();
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.045em;
    color: @white;
    white-space: pre-wrap;
}
