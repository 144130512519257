@import (reference) '../../../assets/styles/styles.less';

.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 630px;
    height: 319px;
    background: @grey3;
    padding: 20px;
    gap: 25px;

    &.big {
        width: 1280px;
        height: 319px;
    }

    &.small {
        height: 240px;
        width: 625px;

        .text {
            font-size: 16px;
            line-height: 19px;
            text-align: left;
        }

        .title {
            font-size: 20px;
            line-height: 23px;
        }
    }
}

.imgWrapper {
    flex: 0 1 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.textWrapper {
    flex: 0 1 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    @robotoRegular();
    font-size: 24px;
    line-height: 28px;
    color: @white;
    margin-bottom: 20px;
    white-space: pre-wrap;
}

.text {
    @robotoRegular();
    font-size: 20px;
    line-height: 24px;
    color: @white;
    white-space: pre-wrap;
}

.detailsBtn {
    @textButton();
    @robotoRegular();
    font-size: 20px;
    line-height: 23px;

    &:hover {
        color: @green6;
    }
}

.arrowIcon {
    display: none;
}
