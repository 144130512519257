@import '../../../assets/styles/styles.less';

.greenText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    background: linear-gradient(0deg, #75c062, #75c062),
        linear-gradient(76.82deg, #576265 11.6%, #9ea1a1 31.94%, #576265 38.59%, #848b8a 57.27%, #576265 70.14%, #757a7b 85.34%, #576265 91.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, normal;
}

.productPlace {
    h3,
    h4,
    h2 {
        font-family: 'Furore', sans-serif;
    }

    .listItem {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 30px;
        position: relative;
        padding-left: 23px;

        &:before {
            content: '';
            background: url('../../../assets/images/svg/greenDot.svg') 0 0 no-repeat;
            width: 14px;
            height: 14px;
            display: block;
            position: absolute;
            left: 0;
            top: 3px;
        }
    }

    .block1 {
        background-color: #5c5c5c;
        height: 800px;
        padding: 170px 0 0 66px;

        .posPicWrapper {
            height: 100%;
            width: 100%;
            background: url('../../../assets/images/png/posGroup1.png') bottom 20px right 66px no-repeat;
        }

        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 40px;
            line-height: 46px;
            letter-spacing: 0.135em;
            color: @grey7;
        }

        .shideText {
            margin-top: 20px;
            font-family: 'Furore', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #c3c0c0;
            letter-spacing: 0.135em;
        }

        .flexText {
            color: @white;
            display: flex;
            justify-content: flex-start;
            gap: 300px;
            margin-top: 110px;
        }

        .sideBlock {
            h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 30px;

                span {
                    font-size: 40px;
                }
            }
        }

        @media all and (max-width: @mobileWidth) {
            padding: 85px 20px 0;
            background: @black;
            height: 550px;

            .posPicWrapper {
                background: none;
            }

            h2 {
                font-size: 18px;
                line-height: 164.6%;
                letter-spacing: 0.195em;
                color: #ffffff;
            }

            .shideText {
                margin-top: 0;
                font-size: 16px;
                line-height: 164.6%;
                letter-spacing: 0.195em;
                color: #a4a4a4;
            }

            .flexText {
                display: block;
                margin: 20px auto;
                max-width: 455px;
            }

            .sideBlock {
                padding-left: 100px;

                h3 {
                    font-size: 16px;
                    line-height: 18px;

                    span {
                        font-size: 20px;
                    }
                }
            }

            .side1 {
                background: url('../../../assets/images/png/posTop.png') top left no-repeat;
                background-size: 100px 148px;
                margin-bottom: 40px;
            }

            .side2 {
                background: url('../../../assets/images/png/posBottom.png') top 10px right no-repeat;
                background-size: 60px 145px;
                padding-left: 10px;
                padding-right: 80px;
                min-height: 170px;
            }

            .listItem {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
                padding-left: 14px;

                &:before {
                    width: 8px;
                    height: 8px;
                    background-size: contain;
                }
            }
        }
    }

    .block2 {
        color: @white;
        height: 670px;
        background: url('../../../assets/images/png/posGroup.png') center left 0 no-repeat;
        display: flex;
        justify-content: flex-end;

        .sideTextPlace {
            margin-top: 88px;
            width: 43%;

            .listItem {
                line-height: 25px;
            }
        }

        .buttonsBlock {
            .aside {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 37px;
            }
        }

        .greyBlock {
            min-width: 211px;
            min-height: 116px;
            background: linear-gradient(177.17deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
            backdrop-filter: blur(4.12023px);
            padding: 33px 0 0 0;
            box-sizing: border-box;
            font-family: 'Roboto', sans-serif;
            text-align: center;

            .greenText {
                font-style: normal;
                font-weight: 900;
                font-size: 24.8643px;
                line-height: 29px;
                background: linear-gradient(0deg, #75c062, #75c062),
                    linear-gradient(
                        76.82deg,
                        #576265 11.6%,
                        #9ea1a1 31.94%,
                        #576265 38.59%,
                        #848b8a 57.27%,
                        #576265 70.14%,
                        #757a7b 85.34%,
                        #576265 91.31%
                    );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                background-blend-mode: color, normal;
            }

            .whiteDescr {
                margin-top: 7px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
            }
        }

        @media all and (max-width: @mobileWidth) {
            display: block;
            height: 530px;
            background: none;

            .sideTextPlace {
                background: url('../../../assets/images/png/posGroup.png') center left 0 no-repeat;
                background-position: top left;
                background-size: 65%;

                margin: 20px auto;
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 455px;

                .dots {
                    padding: 250px 10px 0 20px;
                    box-sizing: border-box;
                    width: 60%;
                }

                .buttonsBlock {
                    width: 40%;
                }
            }

            .listItem {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
                padding-left: 14px;

                &:before {
                    width: 8px;
                    height: 8px;
                    background-size: contain;
                }
            }

            .buttonsBlock {
                .aside {
                    justify-content: center;
                    padding-top: 20px;
                }
            }

            .greyBlock {
                width: 123px;
                min-width: auto;
                min-height: 60px;
                box-sizing: border-box;
                padding: 20px 5px;

                .greenText {
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 23px;
                }

                .whiteDescr {
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                }
            }
        }
    }

    .block3 {
        height: 730px;
        background: url('../../../assets/images/jpg/girl.jpg') 0 0 no-repeat;
        box-sizing: border-box;
        padding: 327px 0 0 65px;

        h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.005em;
        }

        .subtexts {
            width: 1400px;
            margin-top: 294px;
            position: relative;
            left: -65px;
            display: flex;
            justify-content: center;
            gap: 232px;
        }

        .text1 {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
        }

        @media all and (max-width: @mobileWidth) {
            background: linear-gradient(
                60.9deg,
                #576265 0%,
                #9ea1a1 31.9%,
                #576265 49.26%,
                #848b8a 71.61%,
                #576265 91.79%,
                #757a7b 108.47%,
                #576265 125%
            );
            padding: 0;
            height: auto;

            .girlBg {
                background: url('../../../assets/images/png/baba-s-chekom.png') top right no-repeat;
                background-size: contain;
                box-sizing: border-box;
                padding: 20px;

                h4 {
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: -0.005em;
                    color: #1c1b20;
                    width: 60%;
                }
            }

            .subtexts {
                background: url('../../../assets/images/png/printers.png') top center no-repeat;
                width: 100%;
                margin-top: 154px;
                display: block;
                left: 0;
                height: 680px;

                .text1 {
                    color: @white;
                    height: 211px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    margin-bottom: 15px;
                    padding: 0 60px 20px;
                    box-sizing: border-box;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                }
            }
        }
    }

    .block4 {
        height: 590px;
        background: url('../../../assets/images/jpg/manWithPos.jpg') 0 0 no-repeat;
        padding: 62px 0 0 65px;
        color: @white;

        .greyHeadText {
            font-family: 'Furore', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 37px;
            background: linear-gradient(
                83.66deg,
                #576265 8.53%,
                #9ea1a1 30.37%,
                #848b8a 66.59%,
                #576265 78.79%,
                #576265 113.04%,
                #757a7b 125.96%,
                #576265 135.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        .str1,
        .str2,
        .str3 {
            margin-top: 58px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;

            span {
                .greenText();
            }
        }

        .str2 {
            margin-top: 8px;
        }

        .str3 {
            margin-top: 46px;
        }

        .freeMindImg {
            display: block;
            margin: 87px auto 0;
        }

        @media all and (max-width: @mobileWidth) {
            padding: 20px;
            background-color: @black;
            background: url('../../../assets/images/jpg/box4.jpg') 0 0 no-repeat;
            background-size: cover;
            height: 416px;

            .greyHeadText {
                font-size: 20px;
                line-height: 23px;
                margin-top: 170px;
            }

            .str1,
            .str2,
            .str3 {
                font-size: 14px;
                line-height: 16px;
                margin-top: 23px;

                span {
                    font-size: 20px;
                    line-height: 23px;
                }
            }

            .freeMindImg {
                display: none;
            }
        }
    }

    .block5 {
        position: relative;
        height: 1070px;
        background: linear-gradient(180deg, #5c5c5c 1.1%, #d9d9d9 100%);
        color: @white;
        box-sizing: border-box;
        padding-left: 65px;
        overflow: hidden;

        .bgWrapper {
            height: 100%;
            background: url('../../../assets/images/png/productGroup1.png') 0 0 no-repeat;
        }

        .topWhiteBlockText {
            padding-top: 127px;
            font-family: 'Furore', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 32px;
        }

        p {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            margin-top: 40px;
        }

        h3.centerText {
            position: relative;
            left: -32px;
            text-align: center;
            margin: 240px auto;
            font-family: 'Furore', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 32px;
        }

        .descriptionLine {
            position: relative;
            width: 1270px;
            font-family: 'Roboto', sans-serif;
            display: flex;
            justify-content: space-between;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 125.69%;
            color: #576265;
            margin-top: 428px;

            div {
                width: 266px;
                text-align: center;
            }
        }

        .hand1 {
            position: absolute;
            top: 114px;
            right: 0;
            animation: hand1 4s infinite;
            animation-direction: alternate;
        }

        .hand2 {
            position: absolute;
            top: 70px;
            right: -40px;
            animation: hand2 4s infinite;
            animation-direction: alternate;
        }

        @media all and (max-width: @mobileWidth) {
            padding: 20px 0 0 20px;
            height: 890px;
            max-width: 525px;
            margin: auto;

            .topWhiteBlockText {
                padding: 0;
                font-size: 16px;
                line-height: 18px;
            }

            .hand1,
            .hand2 {
                display: none;
            }

            .bgWrapper {
                height: 100%;
                background: url('../../../assets/images/png/paypass.png') 0 70px no-repeat;
                background-size: contain;

                p {
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 125.69%;
                }

                h3.centerText {
                    margin: 220px 0 40px;
                }

                .centerText {
                    font-size: 16px;
                    text-align: center;
                    left: -10px;
                }

                .descriptionLine {
                    background: url('../../../assets/images/png/pos_pics.png') 0 0 no-repeat;
                    background-size: contain;
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    left: -10px;

                    div {
                        display: none;
                    }
                }
            }
        }
    }

    .block6 {
        height: 569px;
        background: url('../../../assets/images/png/poses.png') 0 0 no-repeat;
        box-sizing: border-box;
        //padding: 65px 0 0 65px;

        .greyText {
            margin-top: 24px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #9ea1a1;
        }

        @media all and (max-width: @mobileWidth) {
            background: url('../../../assets/images/png/business_style.png') 0 0 no-repeat;
            background-size: contain;
            padding: 0;
            height: 500px;

            .businessImg,
            .greyText {
                display: none;
            }
        }
    }

    .block7 {
        position: relative;
        height: 569px;
        box-sizing: border-box;
        //padding: 65px 0 0 65px;

        .cert {
            position: absolute;
            left: 150px;
            top: 38px;
            z-index: 1;
        }

        .certBackground {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .greenTextCert {
            padding-top: 60px;
            margin-right: 68px;
            text-align: right;
            font-size: 28px;
            font-family: 'Furore';
            background: linear-gradient(0deg, #75c062, #75c062),
                linear-gradient(
                    76.82deg,
                    #576265 11.6%,
                    #9ea1a1 31.94%,
                    #576265 38.59%,
                    #848b8a 57.27%,
                    #576265 70.14%,
                    #757a7b 85.34%,
                    #576265 91.31%
                );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            background-blend-mode: color, normal;
        }

        .greyText {
            padding-top: 24px;
            margin-right: 67px;
            text-align: right;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #9ea1a1;
        }

        @media all and (max-width: @mobileWidth) {
            background-size: contain;
            padding: 0;
            height: 408px;

            .cert {
                width: 194.603px;
                height: 276.57px;
                top: 117.68px;
                left: 0px;

                // display: flex;
                // justify-content: center;
                // align-items: flex-end;

                // box-sizing: border-box;
                // text-align: center;

                //margin-bottom: 15px;
                margin: 0 25% 0;
            }

            .greenTextCert {
                font-size: 16px;
                font-family: 'Furore';
                text-align: left;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                padding: 32px 0 0 22px;
            }
            .greyText {
                padding: 10px 0 0 22px;
                text-align: left;
                display: flex;
                flex-direction: column;
                color: #d9d9d9;
                font-size: 14px;
                font-family: 'Roboto';
            }
        }
    }
}

@keyframes hand1 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(900px, 0);
    }
}

@keyframes hand2 {
    0% {
        transform: translate(900px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
