@import (reference) '../../../assets/styles/styles.less';

.container {
    position: relative;
    width: 100%;
    height: 363px;
    background: @grey3;

    .mainText {
        @furoreRegular();
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.135em;
        color: @grey11;
        padding-top: 50px;
        padding-left: 65px;
    }

    .carousel {
        margin-top: 52px;
    }
}
