@import (reference) '../../../assets/styles/styles.less';

.itemHover {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.11em;
    background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
        linear-gradient(76.82deg, #576265 11.6%, #9ea1a1 25.31%, #848b8a 48.06%, #576265 55.72%, #576265 77.23%, #757a7b 85.34%, #576265 91.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.menu {
    display: flex;
    gap: 90px;
    -webkit-box-align: center;
    align-items: center;
    text-align: left;
    margin-left: 60px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0.11em;
}

.menuItem {
    @robotoMedium();
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuItemLink {
    @robotoMedium();
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        .itemHover();
    }
}

.navPolygon {
    position: relative;
    bottom: 1.5px;
    left: 2.5px;
}

.subMenu {
    @robotoMedium();
    position: absolute;
    min-width: 256px;
    top: 23px;
    padding: 20px 16px;
    background: linear-gradient(177.17deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(25px);
    z-index: 1000;
    color: @white;
    border: 3px solid #68686a;
    box-shadow: 0 0 9px 0 rgba(55, 130, 36, 1);

    .menuSubItemLink {
        display: flex;
        align-items: center;
        height: 55px;
        color: @white;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            .itemHover();
        }
    }
}
