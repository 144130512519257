@import (reference) '../../../assets/styles/styles.less';

.simpleBlackCard {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background: #191d1f;
    height: 342px;
    width: 367px;
    padding: 20px 20px 20px 20px;

    &:hover {
        box-shadow: 0 0 28px 9px @green7;
    }

    .feature {
        @furoreRegular();
        font-size: 22px;
        line-height: 25px;
        letter-spacing: 0.08em;
        color: @white;
    }

    .list {
        padding-top: 20px;
        padding-left: 24px;

        .featuresListItem {
            list-style-type: disc;
            list-style: outside;
            @robotoMedium();
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.1em;
            color: @white;
            text-align: left;
            margin-bottom: 5px;
        }
    }

    .detailsBtn {
        cursor: pointer;
        border: none;
        background: none;
        @furoreRegular();
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.08em;
        color: @white;
        margin-left: auto;
        margin-top: auto;
    }
}

@media all and (max-width: @mobileWidth) {
    .simpleBlackCard {
        padding: 10px 10px 10px 10px;
        height: max-content;
        width: 280px;
        margin-top: 10px;

        .feature {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.08em;
        }

        .list {
            padding-top: 10px;
            padding-left: 20px;
            .featuresListItem {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.14em;
            }
        }

        .detailsBtn {
            position: relative;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.08em;
            margin-left: auto;
            margin-top: auto;
            margin-top: 10px;
            background-position: center;
            background-image: url('../../../assets/images/png/blackCardBtnMobile.png');
            height: 39px;
            width: 256px;
        }
    }
}
