@import (reference) '../../../assets/styles/styles.less';

.itemHover {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.11em;
    background: linear-gradient(0deg, #75c062, #75c062), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
        linear-gradient(76.82deg, #576265 11.6%, #9ea1a1 25.31%, #848b8a 48.06%, #576265 55.72%, #576265 77.23%, #757a7b 85.34%, #576265 91.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.menu {
    display: flex;
    gap: 90px;
    -webkit-box-align: center;
    align-items: center;
    text-align: left;
    margin-left: 60px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0.11em;
}

.menuItem {
    @robotoMedium();
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuItemLink {
    @robotoMedium();
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        .itemHover();
    }
}

.navPolygon {
    position: relative;
    bottom: 1.5px;
    left: 2.5px;
}

.subMenu {
    @robotoMedium();
    position: absolute;
    min-width: 256px;
    top: 23px;
    padding: 20px 16px;
    background: linear-gradient(177.17deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(25px);
    z-index: 1000;
    color: @white;
    border: 3px solid #68686a;
    box-shadow: 0 0 9px 0 rgba(55, 130, 36, 1);

    .menuSubItemLink {
        display: flex;
        align-items: center;
        height: 55px;
        color: @white;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            .itemHover();
        }
    }
}

@media all and (max-width: @mobileWidth) {
    .flex {
        display: flex;
        justify-content: space-evenly;
    }

    .languageSwitcher {
        @furoreRegular();
        color: white;

        &.active {
            color: rgba(117, 192, 98, 1);
        }
    }

    .menu {
        display: block;
        margin: 0;
    }
    .fader {
        position: absolute;
        z-index: 100000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: 0.4;
    }

    .mobileMenu {
        position: absolute;
        top: 0;
        right: 0;
        width: 157px;
        z-index: 10000000;
        background-color: #2a2b2d;
    }

    .mobileMenuHead {
        height: 58px;
        padding-right: 28px;
        padding-left: 10px;
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.opened {
            padding-left: 29px;
        }
    }

    .mobileMenuItem {
        background: #2a2b2d;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        height: 58px;
        position: relative;
        text-align: left;
        display: flex;
        align-items: center;
        padding-left: 10px;

        &.arrow {
            &::after {
                position: absolute;
                top: 45%;
                right: 11px;
                display: block;
                content: '';
                height: 8px;
                width: 21px;
                position: absolute;
                background: center no-repeat url('../../../assets/images/svg/arrowMenu.svg');
            }
        }
    }

    .mobileMenuItemLink {
        @furoreRegular();
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        color: #ffffff;
        text-decoration: none;

        &:hover {
            color: rgba(117, 192, 98, 1);
        }
    }

    .mobileMenuItemLinkRoboto {
        @robotoRegular();
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        color: #ffffff;
        text-decoration: none;

        &:hover {
            color: rgba(117, 192, 98, 1);
        }
    }

    .mobileMenuHeading {
        @furoreRegular();
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        color: #9ea1a1;
        padding: 10px 0 17px 10px;
    }

    .burger {
        display: block;
    }
}
