@import (reference) '../../../assets/styles/styles.less';

.container {
    width: 100%;
    background: @grey3;
    padding: 60px 69px 0px 66px;
    height: 450px;
    height: 415px;
    background: @grey7;

    .heading {
        @furoreRegular();
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.135em;
        color: @grey3;
    }

    .ddBlockWrapper {
        margin-top: 46px;
        display: grid;
        gap: 66px;
        grid-template-columns: 1fr 1fr;
    }

    .dd {
        border-left: 4px solid;
        border-top: 4px solid;
        border-color: @grey8;
        position: relative;
        z-index: 200000;
        max-height: 164px;
        width: 604px;
        padding: 44px 67px 40px 30px;
        background: linear-gradient(
            84.92deg,
            #576265 8.71%,
            #848b8a 21.53%,
            #576265 48.51%,
            #9ea1a1 97.08%,
            #576265 115.36%,
            #757a7b 128.53%,
            #576265 138.23%
        );
        transition: all 0.5s;
        overflow: hidden;

        &.ddOpen {
            max-height: 1500px;
        }

        &:last-of-type {
            padding-top: 50px;
        }

        &.right {
            background: linear-gradient(
                84.92deg,
                #576265 8.71%,
                #9ea1a1 30.99%,
                #848b8a 67.95%,
                #576265 80.41%,
                #576265 115.36%,
                #757a7b 128.53%,
                #576265 138.23%
            );
        }

        .ddHead {
            @furoreRegular();
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.135em;
            white-space: pre-wrap;
            color: @white;
        }

        .chevron {
            cursor: pointer;
            position: absolute;
            top: 60px;
            right: 60px;
            transition: all 0.3s;

            &.active {
                transform: rotate(180deg);
            }
        }
    }

    .list {
        @robotoLight();
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.135em;
        color: @white;
        margin-top: 60px;
        white-space: pre-wrap;
        padding-left: 60px;

        .listItem {
            width: 471px;

            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                position: absolute;
                width: 33px;
                height: 33px;
                left: -61px;
                background: @ballGradient;
                background-blend-mode: color, overlay, normal;
                border-radius: 50%;
            }
        }
    }
}
